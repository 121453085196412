<template>
  <validation-observer ref="observer" slim v-slot="{ handleSubmit }">
    <v-row>
      <v-col cols="12" class="text-center d-flex flex-column">
        <h3 class="logo">id</h3>
        <span class="project">
          {{ $t("auth.update_password") }}
        </span>
      </v-col>
      <v-col cols="12">
        <validation-provider slim vid="password" :name="$t('auth.new_password')" rules="required" v-slot="{ errors }">
          <v-text-field
            v-model="password"
            :disabled="loading"
            autocomplete="new-password"
            :type="showPassword ? 'text' : 'password'"
            :label="$t('auth.new_password')"
            color="tertiary"
            :error-messages="errors"
            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="showPassword = !showPassword"
          />
        </validation-provider>
      </v-col>
      <v-col cols="12">
        <validation-provider
          slim
          vid="password2"
          :name="$t('auth.re_new_password')"
          rules="required|password:@password"
          v-slot="{ errors }"
        >
          <v-text-field
            v-model="password2"
            :disabled="loading"
            autocomplete="new-password"
            :type="showPassword2 ? 'text' : 'password'"
            :label="$t('auth.re_new_password')"
            color="tertiary"
            :error-messages="errors"
            :append-icon="showPassword2 ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="showPassword2 = !showPassword2"
          />
        </validation-provider>
      </v-col>
      <v-col cols="12">
        <v-btn
          :loading="loading"
          block
          large
          depressed
          color="primary"
          class="mt-5"
          @click="handleSubmit(changePassword)"
        >
          {{ $t("auth.change_password") }}
        </v-btn>
      </v-col>
    </v-row>
  </validation-observer>
</template>

<script>
  import { mapState } from "vuex";

  export default {
    name: "ChangePassword",
    data: () => ({
      loading: false,
      password: null,
      password2: null,
      showPassword: false,
      showPassword2: false
    }),
    computed: {
      ...mapState({ pendingFeedbacks: state => state.auth.pendingFeedbacks })
    },
    methods: {
      changePassword() {
        this.loading = true;
        this.$api.userService
          .changePassword(null, {
            params: {
              password: this.password
            }
          })
          .then(response => {
            if (response.status === 200) {
              if (!response.data.error) {
                this.$store.commit("auth/RESET_PASSWORD_EXPIRED");
                if (this.pendingFeedbacks.length > 0) {
                  this.$router.push({ name: "pending_feedbacks" });
                } else if (this.$route.params.redirect) {
                  this.$router.push(this.$route.params.redirect);
                } else {
                  this.$router.push({ name: "dashboard" });
                }
              } else if (response.data.error.code === 707) {
                let errorMessage = response.data.error.description;
                if (errorMessage.includes("[")) {
                  errorMessage = errorMessage.replace("[", "");
                  errorMessage = errorMessage.replace("]", "");
                }
                this.$helpers.showNotification(errorMessage, "error", true);
              }
            }
          })
          .catch(console.error)
          .finally(() => (this.loading = false));
      }
    }
  };
</script>

<style scoped>
  .logo {
    font-family: "Paytone One", sans-serif;
    font-size: 100px;
    text-align: center;
    display: block;
    color: var(--v-primary-base);
  }
  .project {
    font-family: "Quicksand", sans-serif;
    font-size: 26px;
    color: var(--v-primary-base);
    text-align: center;
    margin-top: -30px;
  }
</style>
